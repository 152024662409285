/* CheckInContainer.tsx */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Strings } from '../../../../constants/StringConstant';
import useIdleTimer from '../../../../customHooks/useIdleTimer';
import { getScreenTimeOutInMilliSeconds, handleHomeClick } from '../../../../helpers/CommonHelper';
import { RouterName } from '../../../../navigation/RouterName';
import { setNonAnonymousSurveys } from '../../../../redux/actions/ConfigAction';
import { setAPICallInProgress } from '../../../../redux/actions/GlobalAction';
import { RootState, useAppDispatch, useAppSelector } from '../../../../redux/Store';
import { AppConfig } from '../../../../utils/AppConfig';
import { CheckInContainerViewModel } from '../../../../viewModels/CheckInContainerViewModel';
import { AppointmentUIEntity, CheckInViewModel } from '../../../../viewModels/CheckInViewModel';
import LanguagePopupScreen from '../../commonViews/LanguagePopupScreen';
import Loader from '../../commonViews/Loader';
import MessageBanner from '../../commonViews/MessageBanner';
import SomethingWentWrongScreen from '../../commonViews/SomethingWentWrongScreen';
import TopBar from '../../commonViews/TopBar';
import { CheckInContainerScrollableView, CheckInContainerTopView, CheckInContainerView } from '../../styles/StylesCheckIn';
import MatchingContainerScreen, { MatchingComponents } from '../matchingScreen/MatchingContainerScreen';
import NoMatchingScreen from '../matchingScreen/NoMatchingScreen';
import AppointmentListScreen from './appointmentListScreen/AppointmentListScreen';
import CheckInSuccessScreen from './CheckInSuccessScreen';
import PatientDemographicScreen from './patientInfoScreen/PatientDemographicScreen';

const CheckInContainer: React.FC = () => {
    const navigate = useNavigate();
    const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const autoConfirmArrival = useAppSelector((state) => state.configSlice.kioskDetails?.autoConfirmArrival) ?? AppConfig.defaultValues.AutoConfirmArrival
    const autoConfirmMultipleArrival = useAppSelector((state) => state.configSlice.kioskDetails?.autoConfirmMultipleArrival) ?? AppConfig.defaultValues.AutoConfirmMultipleArrival
    const showDemographicDetails = useAppSelector((state) => state.configSlice.kioskDetails?.showDemographicDetails) ?? false
    const screenTimeOut = useAppSelector((state) => state.configSlice.kioskDetails?.screenTimeOut)
    const { isIdle, timeRemaining } = useIdleTimer(getScreenTimeOutInMilliSeconds(screenTimeOut), dispatch, navigate);

    const {
        setTimeOutMessage,
        clearTimeOutMessage,
        handleBackAction,
        onCompletionCheckInPreRequisite,
        handlePostalCodeSelection,
        tryAgainAction,
        triggerNonAnonymousSurvey,
        currentIndex,
        showMatchingScreen,
        showPatientInfoScreen,
        showNoMatchingScreen,
        showAppointmentListScreen,
        showAppointmentSuccessScreen,
        showSomethingWentWrongScreen,
        matchingComponents,
        postCodes,
        selectedAppointments,
        messages,
        timeOutMessageContent,
        matchTitles,
        showPostCode,
        setCurrentIndex,
        setMatchingComponents,
        handleNotYouAction,
        appointments, 
        selectedPatient,
        handleCheckInAPI,
        handlePatientInfoYesAction
    } = CheckInContainerViewModel();

    React.useEffect(() => {
        if (isIdle && timeRemaining === 1000) {
            setTimeOutMessage(`${Strings.TimingOutIn} ${timeRemaining / 1000}`, 1000)
        } else if (isIdle && timeRemaining && timeRemaining <= 3000) {
            setTimeOutMessage(`${Strings.TimingOutIn} ${timeRemaining / 1000}`)
        } else {
            clearTimeOutMessage()
        }
    }, [dispatch, isIdle, navigate, t, timeRemaining])

    function onBackClick() {
        handleBackAction(() => navigate(RouterName.Home))
    }

    React.useEffect(() => {
        return () => {
            dispatch(setNonAnonymousSurveys([]));
        };
    }, [dispatch]);

    const onMatchingContainerCompletion = (matchingComponents: MatchingComponents) => {
        onCompletionCheckInPreRequisite(matchingComponents, autoConfirmArrival, autoConfirmMultipleArrival, showDemographicDetails, dispatch);
    }

    const onPostCodeClick = (matchingComponents: MatchingComponents) => {
        handlePostalCodeSelection(matchingComponents, autoConfirmArrival, autoConfirmMultipleArrival, showDemographicDetails, dispatch);
    }

    const onNoneOfTheAboveOnPostCodeClick = (matchingComponents: MatchingComponents) => {
        setMatchingComponents(matchingComponents);
        handleNotYouAction(matchingComponents, dispatch);
    }

    const onNotYouAction = () => {
        handleNotYouAction(matchingComponents, dispatch);
    }

    const onCloseAction = () => {
        handleHomeClick(dispatch, navigate);
    }

    const onCheckin = (selectedAppointments: AppointmentUIEntity[]) => {
        dispatch(setAPICallInProgress(true));
        handleCheckInAPI(dispatch, showDemographicDetails, selectedAppointments);
    }

    const onDoneAction = () => {
        triggerNonAnonymousSurvey(dispatch, navigate);
    }

    const onYesAction = () => {
        handlePatientInfoYesAction();
    }

    return (
        <CheckInContainerView data-testid="CheckInContainer">
            <CheckInContainerTopView>
                <TopBar
                    isShowHomeButton={true}
                    isShowBackOption={currentIndex >= 0 && !showAppointmentSuccessScreen && !showNoMatchingScreen && !showPatientInfoScreen && !showSomethingWentWrongScreen}
                    handleBackClick={onBackClick}
                    timeOutMessage={t(timeOutMessageContent)}
                />
                {showMatchingScreen && !showNoMatchingScreen ? <MessageBanner message={t(Strings.WeAreAskingTheseQuestionsToHelpIdentifyYou)} /> : null}
            </CheckInContainerTopView>
            {showMatchingScreen ?
                <CheckInContainerScrollableView>
                    <MatchingContainerScreen
                        matchingComponents={matchingComponents}
                        matchTitles={matchTitles}
                        showPostalCodes={showPostCode}
                        postalCodes={postCodes}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        onCompletionMatchingContainer={onMatchingContainerCompletion}
                        onClickPostCode={onPostCodeClick}
                        onNoneOfTheAboveClickOnPostCode={onNoneOfTheAboveOnPostCodeClick}
                    />
                </CheckInContainerScrollableView>
                : null
            }
            {showAppointmentListScreen ?
                <AppointmentListScreen
                    patientName={CheckInViewModel().formatPatientName(selectedPatient)}
                    appointments={appointments}
                    handleNotYouAction={onNotYouAction}
                    handleCloseAction={onCloseAction}
                    handleCheckInAction={onCheckin}
                />
                : null
            }
            {showPatientInfoScreen ?
                <PatientDemographicScreen
                    patientInfo={selectedPatient}
                    handleYesAction={onYesAction}
                /> : null
            }
            {showNoMatchingScreen ?
                <NoMatchingScreen
                    title={t(Strings.CheckIn.WeCantFindYourAppointment)}
                    information={`${t(Strings.CheckTheInformationYouProvidedAndTryAgain)} ${t(Strings.IfTheInformationYouProvidedIsCorrectPleaseSpeakToAMemberOfOurReceptionTeam)}`}
                    matchingTitles={matchTitles}
                    matchingComponents={matchingComponents}
                    closeAction={() => handleHomeClick(dispatch, navigate)}
                    tryAgainAction={tryAgainAction}
                />
                : null
            }
            {showAppointmentSuccessScreen ?
                <CheckInSuccessScreen
                    patientName={CheckInViewModel().formatPatientName(selectedPatient)}
                    handleDoneAction={onDoneAction}
                    selectedAppointments={selectedAppointments}
                    messages={messages}
                /> : null
            }
            {showSomethingWentWrongScreen ?
                <SomethingWentWrongScreen
                    handleCloseAction={() => handleHomeClick(dispatch, navigate)}
                />
                : null
            }
            {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}
            <LanguagePopupScreen />
        </CheckInContainerView>
    );
};

export default CheckInContainer;
